/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
/*@altai-button-box-shadow-x-hover: 0;
@altai-button-box-shadow-y-hover: 4px;
@altai-button-box-shadow-x: 0;
@altai-button-box-shadow-y: 3px;
@altai-button-hover-top: -1px;
@altai-button-border-radius: 15px;
@altai-button-padding-x: 15px;
@altai-button-padding-y: 5px;
@altai-button-margin-x: 10px;
@altai-button-margin-y: 5px;

@altai-button-text-darken-percentage: 40%;
@altai-button-box-shadow-darken-percentage: 15%;
@altai-button-box-shadow-darken-percentage-hover: 15%;*/
/*button colors*/
.altai-theme-admin .btn-altai:hover {
  box-shadow: 0 0 #ab3c3c;
  top: 0;
}
.altai-theme-admin .btn-altai {
  border-radius: 3px;
  color: #fff;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.altai-theme-admin .btn {
  border: none;
  font-family: inherit;
  font-size: 12px;
  background: none;
  cursor: pointer;
  padding: 5px 15px;
  display: inline-block;
  margin: 5px 5px;
  letter-spacing: 1px;
  font-weight: 500;
  outline: none;
  position: relative;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
}
.altai-theme-admin .btn-altai-small {
  font-size: 10px;
  min-width: 25px;
  min-height: 25px;
  padding: 5px;
}
.altai-theme-admin .altai-button-go {
  box-shadow: 0 0 #14f67a;
  color: #012411;
  background-color: #5ef9a4 !important;
}
.altai-theme-admin .altai-button-go:hover {
  box-shadow: 0 0 #14f67a;
}
.altai-theme-admin .altai-button-go:active {
  box-shadow: 0 0 #14f67a;
  top: 0;
}
.altai-theme-admin .altai-button-secondary {
  box-shadow: 0 0 #adadad;
  color: #3a3a3a;
  background-color: #d3d3d3 !important;
}
.altai-theme-admin .altai-button-secondary:hover {
  box-shadow: 0 0 #adadad;
}
.altai-theme-admin .altai-button-secondary:active {
  box-shadow: 0 0 #adadad;
  top: 0;
}
.altai-theme-admin .altai-button-back {
  box-shadow: 0 0 #fbb900;
  color: #161000;
  background-color: #ffcf49 !important;
}
.altai-theme-admin .altai-button-back:hover {
  box-shadow: 0 0 #fbb900;
}
.altai-theme-admin .altai-button-back:active {
  box-shadow: 0 0 #fbb900;
  top: 0;
}
.altai-theme-admin .altai-button-danger {
  box-shadow: 0 0 #ff0c0c;
  color: #ffffff;
  background-color: #ff5858 !important;
}
.altai-theme-admin .altai-button-danger:hover {
  box-shadow: 0 0 #ff0c0c;
}
.altai-theme-admin .altai-button-danger:active {
  box-shadow: 0 0 #ff0c0c;
  top: 0;
}
.altai-theme-admin .altai-button-accent {
  box-shadow: 0 0 #e235ff;
  color: #44004f;
  background-color: #ed82ff !important;
}
.altai-theme-admin .altai-button-accent:hover {
  box-shadow: 0 0 #e235ff;
}
.altai-theme-admin .altai-button-accent:active {
  box-shadow: 0 0 #e235ff;
  top: 0;
}
.altai-theme-admin .altai-button-primary,
.altai-theme-admin .altai-button-secondary.selected {
  box-shadow: 0 0 #007fd6;
  color: #000000;
  background-color: #24a6ff !important;
}
.altai-theme-admin .altai-button-primary:hover,
.altai-theme-admin .altai-button-secondary.selected:hover {
  box-shadow: 0 0 #007fd6;
}
.altai-theme-admin .altai-button-primary:active,
.altai-theme-admin .altai-button-secondary.selected:active {
  box-shadow: 0 0 #007fd6;
  top: 0;
}
